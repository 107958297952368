import corner from "../../../Assets/corner.svg";

const Title = ({ text }) => {
  return (
    <h2 className="w-full md:w-fit p-[20px] relative text-white flex justify-center items-center leading-[40px] text-[50px] md:leading-[76px] md:text-[96px] md:min-h-[161px] md:min-w-[491px] bg-[#303739]">
      {text}
      <img
        src={corner}
        alt=""
        className="top-[8.65px] left-[8.65px] absolute h-[8.65px] w-[8.65px]"
      />
      <img
        src={corner}
        alt=""
        className="bottom-[8.65px] left-[8.65px] absolute h-[8.65px] w-[8.65px]"
      />
      <img
        src={corner}
        alt=""
        className="top-[8.65px] right-[8.65px] absolute h-[8.65px] w-[8.65px]"
      />
      <img
        src={corner}
        alt=""
        className="bottom-[8.65px] right-[8.65px] absolute h-[8.65px] w-[8.65px]"
      />
    </h2>
  );
};

export default Title;
