import { createContext, useState } from "react";
import data from "../Assets/Music/data";

export const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const [isCartOpen, setOpenCart] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const [file, setfile] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <AppContext.Provider
      value={{
        isCartOpen,
        setOpenCart,
        isMenuOpen,
        setMenuOpen,
        file,
        setfile,
        isPlaying,
        setIsPlaying,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
