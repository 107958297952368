import image from "../../Assets/Speakers/WAF SP-01 - 05 .jpg";

import mat from "../../Assets/Speakers/Grey.png";
import mat2 from "../../Assets/Speakers/Silv.png";
import mat3 from "../../Assets/Speakers/Brow.png";

import wood1 from "../../Assets/Speakers/Wood1.png";
import wood2 from "../../Assets/Speakers/wood2.png";

import RedirectButton from "../../components/RedirectButton";

import cable from "../../Assets/Speakers/grey-cable.png";
import { Link } from "react-router-dom";

const Materials = () => {
  return (
    // General
    <section className="w-full flex flex-col items-center py-20 justify-center">
      {/* container */}
      <div className="max-w-[1680px] px-[16px] md:px-[24px] w-full justify-center">
        {/* First Sector */}
        <div className="w-full gap-20 relative text-[50px] text-white max-w-screen-[1680px] flex justify-center">
          <div className="lg:max-w-[50%] w-full text-[#303739] flex flex-col items-start">
            <h2 className="flex text-[#CFC8C6] md:leading-[93.6px] leading-[51.2px] md:text-[96px] text-[50px] flex-col">
              Materials
            </h2>

            <div className="text-[#303739] tracking-[0.56px] md:tracking-[0.48px] md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] w-full max-w-[611px] mt-[32px] md:mt-[166px]">
              Our limited production runs of the SP-01 features a baltic birch
              cabinet, clad with hardwood veneer and finished with your choice
              of gray or navy lacquer. Supported by an anodized aluminum base,
              the SP-01 incorporates a removable grill available in a range of
              acoustic textiles by Danish producer Kvadrat. We offer Waves and
              Frequencies custom cables in a palette of choices that uniquely
              harmonize with our pieces. Painted finishes and grill fabric can
              be matched to personalized Pantone colors—so designers, reach out
              if you’re interested in a custom set. {" "}
            </div>
            <p className="mt-[48px] mb-[24px] hidden md:flex max-w-[611px] text-[#303739] text-[16px] tracking-[0.48px] leading-[20px]">
              Get in touch about custom finishes
            </p>

            <a
              href="mailto:info@wafaudio.com"
              className="hidden md:flex"
            >
              <RedirectButton text={"Contact us"} />
            </a>
          </div>

          <div className="hidden lg:flex max-w-[50%] w-full">
            <img
              src={image}
              alt=""
              className="max-w-[528px]"
            />
          </div>
        </div>

        {/* Materials */}
        <div className="xl:flex-row xl:items-start flex flex-col items-center w-full gap-[24px] mt-[48px] md:mt-[91px]">
          {/* fabric */}
          <div className="max-w-[528px] w-full">
            <h2 className="pb-[16px] md:pb-[24px] md:text-[16px] md:leading-[20px] md:tracking-[0.48px] text-[14] leading-[18px] tracking-[0.56px]">
              Grill fabrics
            </h2>
            <div className="border-[#303739] flex justify-between border-t py-3">
              <span className="text-[13px] leading-[16px] tracking-[0.65px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px]">
                Anthracite
              </span>
              <img
                src={mat}
                alt=""
                className="object-cover object-center md:w-[252px] md:h-[88px] h-[86px] w-[171px]"
              />
            </div>
            <div className="border-[#303739] flex justify-between border-t py-3">
              <span className="text-[13px] leading-[16px] tracking-[0.65px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px]">
                Cloud
              </span>

              <img
                src={mat2}
                alt=""
                className="object-cover object-center md:w-[252px] md:h-[88px] h-[86px] w-[171px]"
              />
            </div>{" "}
            <div className="border-[#303739] flex justify-between border-t py-3">
              <span className="text-[13px] leading-[16px] tracking-[0.65px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px]">
                Rust
              </span>
              <img
                src={mat3}
                alt=""
                className="object-cover object-center md:w-[252px] md:h-[88px] h-[86px] w-[171px]"
              />
            </div>
          </div>

          {/* wood */}
          <div className="max-w-[528px] w-full">
            <h2 className="pb-[16px] md:pb-[24px] md:text-[16px] md:leading-[20px] md:tracking-[0.48px] text-[14] leading-[18px] tracking-[0.56px]">
              Wood finishes
            </h2>
            <div className="border-[#303739] flex justify-between border-t border-b py-3">
              <span className="text-[13px] leading-[16px] tracking-[0.65px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px]">
                Echo gray
              </span>
              <img
                src={wood1}
                alt=""
                className="md:w-[252px] md:h-[140px] h-[171px] w-[171px]"
              />
            </div>
            <div className="flex justify-between py-3">
              <span className="text-[13px] leading-[16px] tracking-[0.65px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px]">
                Dark Navy
              </span>
              <img
                src={wood2}
                alt=""
                className="md:w-[252px] md:h-[140px] h-[171px] w-[171px]"
              />
            </div>{" "}
          </div>

          {/* cables */}
          <div className="max-w-[528px] w-full">
            <h2 className="pb-[16px] md:pb-[24px] md:text-[16px] md:leading-[20px] md:tracking-[0.48px] text-[14] leading-[18px] tracking-[0.56px]">
              Cables
            </h2>
            <div className="flex justify-between py-3 border-[#303739] border-t">
              <div className="flex flex-col">
                <span className="text-[13px] leading-[16px] tracking-[0.65px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px]">
                  Gray
                </span>
                <span className="text-[13px] leading-[16px] tracking-[0.65px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px]">
                  Rust
                </span>
                <span className="text-[13px] leading-[16px] tracking-[0.65px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px]">
                  Black
                </span>
                <span className="text-[13px] leading-[16px] tracking-[0.65px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px]">
                  White
                </span>
                <span className="text-[13px] leading-[16px] tracking-[0.65px] md:text-[14px] md:leading-[18px] md:tracking-[0.56px]">
                  Navy
                </span>
              </div>

              <img
                src={cable}
                alt=""
                className="md:w-[252px] md:h-[296px] h-[171px] w-[171px]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Materials;
