import { useEffect, useState } from "react";

const CartItem = ({ name, amount, price, optionals, image, onDelete, id, updateQuantity }) => {
  const [total, setTotal] = useState(amount);

  const increaseTotal = async () => {
    setTotal((prev) => prev + 1);
    await updateQuantity(id, total + 1);
  };

  const decreaseTotal = async () => {
    if (total === 1) {
      onDelete();
      // delet from server too, this is just visual
      return;
    }
    setTotal((prev) => prev - 1);
    await updateQuantity(id, total - 1);
  };

  return (
    <li className="sm:mb-[60px] mb-[32px] border-t boder-[1px] border-white">
      <div className="flex gap-[16px] sm:gap-[24px] pt-[16px] sm:pt-[24px]">
        <div className="sm:h-[252px] sm:w-[252px] h-[109px] w-[109px] ">
          <img
            src={image} // {img}
            alt=""
            className="h-full w-full"
          />
        </div>

        <div className="flex-grow flex flex-col justify-between">
          <div className="flex flex-col text-white sm:leading-[30px] leading-[20px] tracking-[0.48px] text-[16px] sm:text-[24px]">
            <span>{name} </span>
            {optionals?.map((item) => (
              <span>
                {item.name}: {item.value}
              </span>
            ))}
            <span>Case: White</span>
            <span>Grille: Rust</span>
          </div>

          {/* Amount and price */}
          <div className="w-full flex justify-end">
            <div className="flex w-full flex-row justify-between lg:flex-col items-end text-white sm:leading-[30px] leading-[20px] tracking-[0.48px] text-[16px] sm:text-[24px]">
              <div className="lg:gap-[15px] gap-[20px] flex mr-2">
                <button
                  onClick={decreaseTotal}
                  className="w-[10px]"
                >
                  -
                </button>{" "}
                <span className="min-w-[20px] md:min-w-[30px]">x{total}</span>{" "}
                <button
                  onClick={increaseTotal}
                  className="w-[10px]"
                >
                  +
                </button>
              </div>

              {/* Price */}
              <span>${(price * amount).toLocaleString()}</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CartItem;
