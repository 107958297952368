import cart from "../../Assets/Bag.svg";
import menu from "../../Assets/Menu.svg";
import logo from "../../Assets/Logo.svg";
import logoText from "../../Assets/LogoText.svg";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { Link } from "react-router-dom";

import axios from "axios";

const Header = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setOpenCart, setMenuOpen } = useContext(AppContext);

  const openCart = () => {
    setOpenCart(true);
  };

  const openMenu = () => {
    setMenuOpen(true);
  };

  return (
    <header
      id="home"
      className="absolute z-50 w-full h-[68px] md:h-[116px] flex justify-center"
    >
      <div className="abosolute max-w-screen-3xl w-full p-[16px] md:p-[24px]">
        <div className="hidden mt-[8.49px] md:flex md:ml-[167px]">
          <img
            src={logoText}
            alt=""
            className="md:w-[183.51px] md:h-[56.39px]"
          />
        </div>
      </div>

      <div className="fixed h-[68px] md:h-[116px] p-[16px] md:p-[24px] items-start max-w-screen-3xl w-full flex justify-between">
        <Link to="/">
          <img
            src={logo}
            alt=""
            className="w-[78px] h-[35px] md:h-[68px] md:w-[146px]"
          />
        </Link>

        <div className="flex gap-[16px] items-center">
          <button
            onClick={openCart}
            className=""
          >
            <img
              src={cart}
              alt=""
              className="w-[24px] h-[24px] md:w-[32px] md:h-[32px]"
            />
          </button>

          <button onClick={openMenu}>
            <img
              src={menu}
              alt=""
              className="w-[24px] h-[24px] md:w-[32px] md:h-[32px]"
            />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
