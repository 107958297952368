import hero from "../../Assets/Speakers/WAF SP-01 - 02.jpg";
import two from "../../Assets/Speakers/ENV_SP-01_01.jpg";

const Speakers = () => {
  return (
    <>
      {/* Gradient */}
      <div className="flex w-full flex-col absolute z-10">
        <div className="w-full md:h-[116px] h-[68px] bg-white"></div>
        <div className="w-full md:h-[116px] h-[68px] bg-gradient-to-b from-white to-[#fff0]"></div>
      </div>

      <section className="w-full flex justify-center">
        <div className="w-full gap-[10px] items-center max-w-screen-3xl">
          <div className="relative max-w-screen-3xl flex w-full">
            <img
              src={hero}
              alt=""
              className="md:mt-[116px] max-w-[628px] w-full h-full max-h-[628px] sm:max-h-[1127px] sm:max-w-screen-3xl sm:h-full sm:w-full mt-[68px]"
            />
          </div>

          {/* Text and Image */}
          <div className="flex gap-[51px] flex-wrap md:justify-between justify-between items-start w-full p-[16px] md:p-[24px]">
            {/* subtitle */}

            <h2 className="text-[#CFC8C6] md:text-[96px] text-[50px] md:leading-[76px] leading-[40px]">
              SP–01
            </h2>

            {/* Text */}
            <div className="text-[14px] md:leading-[20px] leading-[18px] mr-0 md:mr-[162px] tracking-[0.56px] md:tracking-[0.48px] md:text-[16px] w-full max-w-[666px] text-[#303739]">
              Our first speaker is the product of years of tuning and a lifetime 
              of loving music, resulting in a blend of pinpoint clarity and powerful 
              bass. Our philosophy is that sound is touch by distance, bringing people 
              together whether in the same room or apart. SP-01 animates any space with 
              all the warmth and depth that is possible in music, properly elevating 
              the experience of everyday listening.
              <br />
              <br />
              Plug the passive SP-01 into your favorite amp and you’re good to go, 
              whether it’s a classic solid-state receiver or a modern unit with 
              built-in streaming. If you need any help choosing an amplifier, drop 
              us a line—we have suggestions if you’re acoustically curious and 
              starting from scratch or deep in the gear vortex and looking for 
              your next obsession.. 
            </div>
          </div>

          <div className="px-[16px] w-full flex justify-center">
            <img
              src={two}
              alt=""
              className="max-w-[1080px] w-full mt-[16px] md:mt-[36px]"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Speakers;
