import mix1 from "./Songs/WAF_MIX01.mp3";

const mix1Description = (
  <>
    Like the SP-01, our selector series of “tracks for a good hi-fi system” is
    dedicated to the home listening party. We asked our friend and artist Jessy
    Lanza to craft the inaugural mix and it’s a stunner.
    <br />
    <br /> Jessy Lanza has built a singular sound from a blend of influences
    spanning decades, encompassing synth pop, post-disco R&B, and the
    crosscurrents of underground dance genres. A singer, songwriter,
    instrumentalist, producer, and DJ, Jessy is an integral part of the U.K.
    label, Hyperdub.
    <br />
    <br /> Begonia leaf by eric epstein.
  </>
);

const songs = [
  {
    artistName: "Jessy Lanza",
    SongName: "WAF MIX - 01",
    description: mix1Description,
    img: "https://i.ibb.co/NKQMThY/WAF-mix-01-Jessy-Lanza.jpg",
    mp3File: mix1,
  },
];

export default songs;
