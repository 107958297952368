import { SHOPIFY_BACKEND_URL } from "../constants/shopify";

// Fetch product list from shopify
export const fetchProductList = async () => {
    const productsJson = await fetch(SHOPIFY_BACKEND_URL + '/products');
    const products = await productsJson.json();

    return products;
}

// Create new checkout session if none exists
export const createCheckout = async () => {
    const checkoutJson = await fetch(SHOPIFY_BACKEND_URL + '/checkout', {
        method: 'POST'
    });

    const checkout = await checkoutJson.json();
    return checkout;
};

// Fetch current checkout if it exists
export const fetchCheckout = async (checkoutId) => {
    const checkoutJson = await fetch(SHOPIFY_BACKEND_URL + '/checkout/current', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: checkoutId
        }),
    });

    const checkout = await checkoutJson.json();

    return checkout;
};

// Add item to checkout session
export const addItemToCheckout = async (checkoutId, item) => {
    const checkoutJson = await fetch(SHOPIFY_BACKEND_URL + '/checkout/add', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: checkoutId,
            item: item
        }),
    });

    console.log(checkoutJson);
    const checkout = await checkoutJson.json();
    console.log(checkout);

    return checkout;
};

// Remove item from checkout session
export const removeItemFromCheckout = async (checkoutId, lineItemId) => {
    console.log(lineItemId.checkoutId)
    const checkoutJson = await fetch(SHOPIFY_BACKEND_URL + '/checkout/remove', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: checkoutId,
            items: lineItemId
        }),
    });

    const checkout = await checkoutJson.json();

    return checkout;
};

export const updateItemInCheckout = async (checkoutId, lineItemId, quantity) => {
    console.log(checkoutId, lineItemId, quantity)
    const checkoutJson = await fetch(SHOPIFY_BACKEND_URL + '/checkout/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: checkoutId,
            items: [{
                id: lineItemId,
                quantity: quantity
            }]
        }),
    });

    const checkout = await checkoutJson.json();
    console.log(checkout)
    return checkout;
};