const SoundAdvice = () => {
  return (
    <section className="p-[16px] w-full items-center flex flex-col justify-center">
      {/* Gradient */}
      <div className="absolute top-0 -z-10 w-full">
        <div className="h-[68px] md:h-[116px] bg-[#E8E8E3]"></div>
        <div className="h-[68px] md:h-[116px] bg-gradient-to-b from-[#E8E8E3] to-[#EDEDEA00]"></div>
      </div>

      <div className="w-full max-w-[804px] flex-col">
        <h2 className="text-[#2D3745] text-center mt-[84px] mb-[54px] text-[50px] leading-[40.2px] md:text-[96px] md:leading-[76px] md:mt-[156px] md:mb-[120px]">
          Sound advice
        </h2>
        <div className="max-w-full flex justify-center flex-wrap">
          {/* Lorem */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[60px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              Feugiat quis nascetur consectetur nulla integer ultricies. Eget
              nam amet fermentum nulla pellentesque sit ornare. Condimentum in
              et egestas blandit eleifend leo tortor mattis.
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              Et ornare leo ultricies orci natoque. Pellentesque sit nulla
              ornare sed. Eu eleifend elit vitae laoreet nullam at hac pretium
              pellentesque. Blandit enim volutpat mauris aliquet interdum et
              ornare fringilla. Massa vel neque morbi cursus pharetra ut sit
              amet ornare. Scelerisque faucibus nibh ut ut hac ut eu orci id.
              Pretium habitant nunc est vulputate ultrices sed proin. Egestas
              porttitor maecenas id urna justo in sed. Viverra ante in sit felis
              gravida augue id. Sit egestas pulvinar quam non purus lectus
              ultrices condimentum maecenas. Nec diam id tortor rhoncus amet
              sagittis. Accumsan phasellus mi turpis viverra vitae aliquet
              purus. Felis pulvinar odio tellus congue duis elit eu.
              <br />
              <br />
              Cras potenti neque nullam blandit ornare auctor. Nulla dignissim
              sapien penatibus viverra. Id tempus nibh quam justo nisl in odio
              nunc. Donec dignissim suspendisse imperdiet ac tempus ornare. A
              tortor in id risus. Nisi faucibus lectus tempor quis facilisis
              adipiscing nunc. Eget elementum sed habitant maecenas aliquam
              semper purus. Morbi sed hendrerit praesent mi facilisis in. In et
              est hendrerit enim.
            </p>
          </div>

          {/* Lorem */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[60px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              Feugiat quis nascetur consectetur nulla integer ultricies. Eget
              nam amet fermentum nulla pellentesque sit ornare. Condimentum in
              et egestas blandit eleifend leo tortor mattis.
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              Et ornare leo ultricies orci natoque. Pellentesque sit nulla
              ornare sed. Eu eleifend elit vitae laoreet nullam at hac pretium
              pellentesque. Blandit enim volutpat mauris aliquet interdum et
              ornare fringilla. Massa vel neque morbi cursus pharetra ut sit
              amet ornare. Scelerisque faucibus nibh ut ut hac ut eu orci id.
              Pretium habitant nunc est vulputate ultrices sed proin. Egestas
              porttitor maecenas id urna justo in sed. Viverra ante in sit felis
              gravida augue id. Sit egestas pulvinar quam non purus lectus
              ultrices condimentum maecenas. Nec diam id tortor rhoncus amet
              sagittis. Accumsan phasellus mi turpis viverra vitae aliquet
              purus. Felis pulvinar odio tellus congue duis elit eu.
              <br />
              <br />
              Cras potenti neque nullam blandit ornare auctor. Nulla dignissim
              sapien penatibus viverra. Id tempus nibh quam justo nisl in odio
              nunc. Donec dignissim suspendisse imperdiet ac tempus ornare. A
              tortor in id risus. Nisi faucibus lectus tempor quis facilisis
              adipiscing nunc. Eget elementum sed habitant maecenas aliquam
              semper purus. Morbi sed hendrerit praesent mi facilisis in. In et
              est hendrerit enim.
            </p>
          </div>

          {/* Lorem */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[60px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              Feugiat quis nascetur consectetur nulla integer ultricies. Eget
              nam amet fermentum nulla pellentesque sit ornare. Condimentum in
              et egestas blandit eleifend leo tortor mattis.
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              Et ornare leo ultricies orci natoque. Pellentesque sit nulla
              ornare sed. Eu eleifend elit vitae laoreet nullam at hac pretium
              pellentesque. Blandit enim volutpat mauris aliquet interdum et
              ornare fringilla. Massa vel neque morbi cursus pharetra ut sit
              amet ornare. Scelerisque faucibus nibh ut ut hac ut eu orci id.
              Pretium habitant nunc est vulputate ultrices sed proin. Egestas
              porttitor maecenas id urna justo in sed. Viverra ante in sit felis
              gravida augue id. Sit egestas pulvinar quam non purus lectus
              ultrices condimentum maecenas. Nec diam id tortor rhoncus amet
              sagittis. Accumsan phasellus mi turpis viverra vitae aliquet
              purus. Felis pulvinar odio tellus congue duis elit eu.
              <br />
              <br />
              Cras potenti neque nullam blandit ornare auctor. Nulla dignissim
              sapien penatibus viverra. Id tempus nibh quam justo nisl in odio
              nunc. Donec dignissim suspendisse imperdiet ac tempus ornare. A
              tortor in id risus. Nisi faucibus lectus tempor quis facilisis
              adipiscing nunc. Eget elementum sed habitant maecenas aliquam
              semper purus. Morbi sed hendrerit praesent mi facilisis in. In et
              est hendrerit enim.
            </p>
          </div>

          {/* Lorem */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[60px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              Feugiat quis nascetur consectetur nulla integer ultricies. Eget
              nam amet fermentum nulla pellentesque sit ornare. Condimentum in
              et egestas blandit eleifend leo tortor mattis.
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              Et ornare leo ultricies orci natoque. Pellentesque sit nulla
              ornare sed. Eu eleifend elit vitae laoreet nullam at hac pretium
              pellentesque. Blandit enim volutpat mauris aliquet interdum et
              ornare fringilla. Massa vel neque morbi cursus pharetra ut sit
              amet ornare. Scelerisque faucibus nibh ut ut hac ut eu orci id.
              Pretium habitant nunc est vulputate ultrices sed proin. Egestas
              porttitor maecenas id urna justo in sed. Viverra ante in sit felis
              gravida augue id. Sit egestas pulvinar quam non purus lectus
              ultrices condimentum maecenas. Nec diam id tortor rhoncus amet
              sagittis. Accumsan phasellus mi turpis viverra vitae aliquet
              purus. Felis pulvinar odio tellus congue duis elit eu.
              <br />
              <br />
              Cras potenti neque nullam blandit ornare auctor. Nulla dignissim
              sapien penatibus viverra. Id tempus nibh quam justo nisl in odio
              nunc. Donec dignissim suspendisse imperdiet ac tempus ornare. A
              tortor in id risus. Nisi faucibus lectus tempor quis facilisis
              adipiscing nunc. Eget elementum sed habitant maecenas aliquam
              semper purus. Morbi sed hendrerit praesent mi facilisis in. In et
              est hendrerit enim.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SoundAdvice;
