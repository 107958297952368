import { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const apiKey = process.env.REACT_APP_APIKEY;
  const listId = process.env.REACT_APP_LISTID;

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);

  const subscribeToKlaviyo = async () => {
    try {
      const response = await fetch(
        "https://serverapi-lhg3.onrender.com/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            apiKey: apiKey,
            listId: listId,
            email: email,
          }),
        }
      );

      if (response.ok) {
        // Successful subscription
        console.log("Subscription successful");
      } else {
        // Handle error
        console.error("Subscription failed");
      }
    } catch (error) {
      console.error("Error subscribing:", error.message);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = () => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Perform validation if needed
    const state = validateEmail(email) !== null;
    setIsValid(validateEmail(email) !== null);

    if (!state) {
      return;
    }

    // Subscribe to Klaviyo
    subscribeToKlaviyo();
    setEmail("");
  };

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:flex justify-center bg-[#303739]">
        <div className="max-w-screen-3xl gap-[24px] w-full pb-[96px] p-[24px] text-white text-[20px] letter-spacing:0.03em; leading-[24px] flex justify-center">
          {/* Title */}
          <span className="w-full justify-start flex flex-col">
            <a
              className="w-fit"
              href="#home"
            >
              Waves and <br /> Frequencies
            </a>
          </span>

          {/* Middle */}
          <div className="w-full gap-[24px] flex flex-col">
            <Link to="/formalities">
              <span className="hover:underline">Formalities & FAQ</span>
            </Link>

            <Link to="/soundadvice">
              <span className="hover:underline">Sound advice</span>
            </Link>

            <Link to="/info">
              <span className="hover:underline">Info</span>
            </Link>

            <Link to="/listen">
              <span className="hover:underline">Listen</span>
            </Link>
          </div>

          {/* Email */}
          <div className="w-full gap-[24px] flex flex-col">
            <a
              className="hover:underline"
              href="mailto:info@wafaudio.com"
            >
              <span>Email</span>
            </a>

            <a
              className="hover:underline"
              href="https://www.instagram.com/waf_audio"
              target="_blank"
              rel="noreferrer"
            >
              <span>Instagram</span>
            </a>

            <div className="flex flex-col gap-[12px] ">
              <span>Newsletter</span>

              <span className="text-[13px] leading-[16px] tracking-[0.65px] pt-[10px] md:pt-[12px]">
                Enter your email to get updates on what we’re up to.
              </span>
            </div>

            <div className="rounded-md h-[38px] border border-[#CFC8C6] flex justify-between">
              <input
                value={email}
                onChange={handleEmailChange}
                type="text"
                placeholder="Your email"
                className={`${
                  isValid ? "" : "text-red-200"
                }  bg-transparent px-2 placeholder:text-[#CFC8C6] text-[#CFC8C6] placeholder:text-[14px] text-[14px] outline-none`}
              />
              <button
                onClick={handleFormSubmit}
                className="border-l w-[68px] text-[14px]"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="lg:hidden flex justify-center bg-[#303739]">
        <div className="w-full gap-[32px] flex-col p-[16px] pb-[84px] text-white text-[13px] tracking-[0.65px] leading-[16px] flex justify-center">
          {/* First Row */}
          <div className="flex border-b-[1px] pb-[32px]">
            <a
              href="#home"
              className="w-full flex flex-col"
            >
              Waves and <br /> Frequencies
            </a>

            <div className="w-full gap-[20px] flex flex-col">
              <Link to="/formalities">
                <span>Formalities & FAQ</span>
              </Link>

              <Link to="/soundadvice">
                <span>Sound advice</span>
              </Link>

              <Link to="/info">
                <span>Info</span>
              </Link>

              <Link to="/listen">
                <span>Listen</span>
              </Link>
            </div>
          </div>

          {/* Second Row */}
          <div className="flex border-b-[1px] pb-[32px]">
            <div className="w-full flex flex-col"></div>

            <div className="w-full gap-[20px] flex flex-col">
              <span>Email</span>
              <span>Instagram</span>
            </div>
          </div>

          {/* Third Row */}
          <div className="w-full pt-[32px] flex flex-col text-[20px]">
            <span>Newsletter</span>
            <span className="text-[13px] leading-[16px] tracking-[0.65px] pt-[10px]">
              Enter your email to get updates on what we’re up to.
            </span>
            <div className="mt-[32px] flex w-full justify-center">
              <div className="w-full rounded-md h-[38px] border-[1px] border-[#CFC8C6] flex justify-between">
                <input
                  value={email}
                  onChange={handleEmailChange}
                  type="text"
                  placeholder="Your email"
                  className={`${
                    isValid ? "" : "text-red-200"
                  } bg-transparent w-[290px] px-3 placeholder:text-[#CFC8C6] placeholder:text-[13px] placeholder:leading-[16px] placeholder:tracking-[0.65px] text-[#CFC8C6] text-[13px] leading-[16px] tracking-[0.65px] outline-none`}
                />
                <button
                  onClick={handleFormSubmit}
                  className="text-white border-l border-[1px]-l w-[68px] text-[14px]"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
