import { useContext } from 'react';
import { addItemToCheckout } from '../../utils/shopify'
import { ShopContext } from '../../context/shopContext';

const AddToCart = ({ productId, checkoutId }) => {

  const { setCheckout } = useContext(ShopContext);

  const addToCart = async (_productId) => {
    const item = [
      {
        variantId: productId,
        quantity: 1,
      }
    ]

    const checkout = await addItemToCheckout(checkoutId, item);
    setCheckout(checkout);
  };

  return (
    <button onClick={async () => await addToCart(productId)} className="hover:bg-white hover:text-[#303739] transition-all duration-300 border border-[#303739] rounded-[1000px] w-full md:w-[528px] text-white text-[14px] md:text-[16px] md:leading-[20px] leading-[18px] bg-[#303739] h-[58px] md:h-[60px]">
      Add to cart
    </button>
  );
};

export default AddToCart;
