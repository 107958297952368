import Hero from "./Hero";
import Gradient from "./Gradient";
import Materials from "./Materials";
import Features from "./Features";

const SP01Info = () => {
  return (
    <>
      <Hero />
      <Gradient />
      <Materials />
      <Features />
    </>
  );
};

export default SP01Info;
