import AddToCart from "../../components/AddToCartBtn";

import mat from "../../Assets/mat.svg";
import drop from "../../Assets/dropdown.svg";

import darkNavy from "../../Assets/Materials/Dark-Navy.jpg";
import cloudGray from "../../Assets/Materials/Cloud-Gray.jpg";
import anthracite from "../../Assets/Materials/Grill-fabric-anthracite.jpg";
import rust from "../../Assets/Materials/Grill-fabric-rust.jpg";
import white from "../../Assets/Materials/Grill-frabric-white.jpg";

import blackCord from '../../Assets/Materials/black-cord-2.jpg';
import blueCord from '../../Assets/Materials/blue-cord-2.jpg';
import greyCord from '../../Assets/Materials/grey-cord-2.jpg';
import rustCord from '../../Assets/Materials/rust-cord-2.jpg';
import whiteCord from '../../Assets/Materials/white-cord-2.jpg';

import { useContext, useEffect, useState } from "react";
import ItemOption from "../../components/UI/DropOption";
import Title from "../../components/UI/Title";
import { useParams } from "react-router-dom";
import { ShopContext } from "../../context/shopContext";

const Product = () => {
  const { id } = useParams();

  const { productList } = useContext(ShopContext);
  const { checkout } = useContext(ShopContext);

  const [currentProduct, setCurrentProduct] = useState();
  const [productOptions, setProductOptions] = useState();
  const [variant, setVariant] = useState();

  const [index, setIndex] = useState(0);

  const [isSpecial, setSpecial] = useState(true);
  const specs = true;

  const increaseIndex = () => {
    if (currentProduct.images.length > 0 && index >= currentProduct.images.length - 1) {
      setIndex(0);
      return;
    }
    setIndex((prevState) => prevState + 1);
  };

  const decreaseIndex = () => {
    if (index === 0 && currentProduct.images.length > 0) {
      setIndex(currentProduct.images.length - 1);
      return;
    }
    setIndex((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (id && productList.length > 0) {
      const product = productList.filter((elem) => elem.id.split('/')[elem.id.split('/').length - 1] == id)[0];
      setCurrentProduct(product);
      setVariant(product.variants[0].id);
    }
  }, [id, productList]);

  const getCableOptions = () => {
    const cableColours = [{ name: 'Gray', img: greyCord }, { name: 'Black', img: blackCord }, { name: 'White', img: whiteCord }, { name: 'Rust', img: rustCord }, { name: 'Blue', img: blueCord }];

    return (
      <>
        <ItemOption
          title={"Color"}
          materials={cableColours}
          img={mat}
          onUpdate={(material) => {
            setProductOptions((prevState) => ({ ...prevState, cable: { name: material.name, img: material.img } }))
            if (material.name) {
              const variant = currentProduct?.variants.filter((variant) => {
                return variant.title.includes(material.name);
              })

              if (variant.length > 0) {
                setVariant(variant[0].id);
              }
            }
          }}
          selectedMaterial={productOptions?.cable ? productOptions.cable : cableColours[0]}
        ></ItemOption>
      </>
    )
  };

  const getSpeakerOptions = () => {
    const caseMaterials = [{ name: "Dark navy", img: darkNavy }, { name: "Echo gray", img: cloudGray }];
    const grillMaterials = [{ name: "Anthracite", img: anthracite }, { name: "Rust", img: rust }, { name: "White", img: white }];

    return (
      <>
        <ItemOption
          title={"Case"}
          materials={caseMaterials}
          img={mat}
          onUpdate={(material) => {
            setProductOptions((prevState) => ({ ...prevState, case: { name: material.name, img: material.img } }))
            if (productOptions?.grill.name && material.name) {
              const variant = currentProduct?.variants.filter((variant) => {
                return variant.title.includes(material.name) && variant.title.includes(productOptions.grill.name);
              })
              if (variant.length > 0) {
                setVariant(variant[0].id);
              }
            }
          }}
          selectedMaterial={productOptions?.case ? productOptions.case : caseMaterials[0]}
        />
        <ItemOption
          title={"Grill"}
          materials={grillMaterials}
          img={mat}
          onUpdate={(material) => {
            setProductOptions((prevState) => ({ ...prevState, grill: { name: material.name, img: material.img } }))
            if (productOptions?.case.name && material.name) {
              const variant = currentProduct?.variants.filter((variant) => {
                return variant.title.includes(material.name) && variant.title.includes(productOptions.case.name);
              })

              if (variant.length > 0) {
                setVariant(variant[0].id);
              }
            }
          }}
          selectedMaterial={productOptions?.grill ? productOptions.grill : grillMaterials[0]}
        />
      </>
    )
  }

  return (
    <section className="w-full flex flex-col justify-center items-center">
      {/* Image and text */}
      <div className="max-w-[1680px] px-[16px] w-full">
        {/* Banner or Title */}
        <div className="flex mt-[68px] md:mt-[116px] md:mb-[60px] mb-[31px] justify-center items-center w-full">
          {isSpecial ? (
            <Title text={currentProduct?.title} />
          ) : (
            <h2 className="text-[#2D3745] leading-[40px] text-[50px] md:leading-[76px] md:text-[96px]">
              Record Brush
            </h2>
          )}
        </div>

        {/* Content */}
        <div className="max-w-[1494px] w-full gap-[32px] flex flex-wrap justify-center">
          {/* Image and control */}
          <div className="w-full md:max-h-[863x] h-full max-w-[804px] items-center justify-between flex-col">
            <div className="w-full justify-center items-center max-h-[804px] h-full flex max-w-[804px]">
              <img
                src={currentProduct?.images.length > 0 ? currentProduct.images[index].src : ''}
                alt=""
                className="w-auto h-auto"
              />
            </div>

            {/* Control Buttons */}
            <div className="w-full flex justify-center md:mt-[23px] mt-[10px]">
              <div className="flex items-center justify-around w-[132px] h-[36px] text-[#000]">
                <button
                  onClick={decreaseIndex}
                  className="w-[36px] h-[36px]"
                >
                  <img
                    src={drop}
                    alt=""
                    className="rotate-180"
                  />
                </button>
                <span className="mx-[12px] w-[36px]">
                  {" "}
                  {index + 1} / {currentProduct?.images.length}
                </span>
                <button
                  onClick={increaseIndex}
                  className="w-[36px] h-[36px]"
                >
                  <img
                    src={drop}
                    alt=""
                    className=""
                  />
                </button>{" "}
              </div>
            </div>
          </div>

          {/* Stats */}
          <div className="flex flex-col justify-between max-w-[528px] md:min-h-[877px] w-full">
            <div>
              {/* Text */}
              {/* <h2 className="md:text-[24px] text-[16px] md:mb-[36px] mb-[16px] leading-[20px] md:leading-[30px] text-[#303739] tracking-[0.48px]">
                The SP-01 is a distinctive new speaker that a balance between
                good design and enveloping sound
              </h2> */}
              <p className="mb-[48px] md:mb-[60px] md:text-[16px] text-[14px] text-[#303739] md:leading-[20px] leading-[18px] tracking-[0.56px] md:tracking-[0.48px]">
                {currentProduct?.description}
              </p>
            </div>

            {/* Options */}
            <div>
              <ul className="flex flex-col md:gap-[24px] gap-[16px]">
                {id && id == '8203362631900' ? getCableOptions() : getSpeakerOptions()}
              </ul>

              <h2 className="my-[32px] md:my-[36px] md:text-[30px] text-[24px] md:leading-[36px] leading-[30px] tracking-[0.48px] md:tracking-[0.6px]">
                ${parseFloat(currentProduct?.variants[0].price.amount).toLocaleString()}
              </h2>

              <div className="flex justify-center">
                <AddToCart checkoutId={checkout?.id} productId={variant} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* In-page footer */}
      <>
        {/* In some cases the items will not have any descriptio */}
        {!specs ? (
          <>
            <div className="h-[120px] md:mt-[120px] mt-[64px] w-full bg-gradient-to-b from-[#fff] to-[#E8E8E3]"></div>
            <div className="w-full flex px-[16px] md:px-[24px] justify-center min-h-[510px] bg-[#E8E8E3]">
              <div className="max-w-[1632px] w-full mb-[64px]">
                <h2 className="md:text-[96px] text-[50px] text-white leading-[40px] md:leading-[76px]">
                  Specs
                </h2>

                {/* description */}
                <div className="flex lg:grid grid-cols-2 flex-wrap gap-[30px] md:mt-[64px] mt-[48px] text-[#303739] w-full md:text-[14px] text-[13px] md:leading-[18px] leading-[16px] md:tracking-[0.56px] tracking-[0.65px]">
                  {/* Audio */}
                  <div className="flex max-w-[801px] flex-col w-full">
                    <span className="py-[8px]">Audio</span>
                    <span className="gap-[16px] py-[8px] border-t border-[#303739]">
                      Passive (pair)
                    </span>
                    <span className="gap-[16px] flex py-[8px] border-t border-[#303739]">
                      <span className="w-[108px] md:w-[170px]">
                        Sensitivity
                      </span>{" "}
                      88 decibels
                    </span>
                    <span className="gap-[16px] flex py-[8px] border-t border-[#303739]">
                      <span className="min-w-[108px] md:w-[170px]">
                        Response
                      </span>{" "}
                      35hz—20khz
                    </span>
                    <span className="gap-[16px] flex py-[8px] border-t border-[#303739]">
                      <span className="min-w-[108px] md:w-[170px]">
                        Bass driver
                      </span>{" "}
                      8in aluminum cone woofer
                    </span>
                    <span className="gap-[16px] flex py-[8px] border-t border-b border-[#303739]">
                      <span className="min-w-[108px] md:w-[170px]">
                        Treble driver
                      </span>{" "}
                      1in aluminum magnesium dome tweeter
                    </span>
                  </div>

                  {/* Dimensions */}
                  <div className="flex max-w-[801px] flex-col w-full">
                    <span className="py-[8px]">Dimensions</span>

                    <span className="gap-[16px] flex py-[8px] border-t border-[#303739]">
                      <span className="w-[108px] md:w-[170px]">Height</span>{" "}
                      <span className="w-[108px] md:w-[170px]">76cm</span>
                      <span className="w-[108px] md:w-[170px]">30in</span>
                    </span>
                    <span className="gap-[16px] flex py-[8px] border-t border-[#303739]">
                      <span className="w-[108px] md:w-[170px]">Width</span>{" "}
                      <span className="w-[108px] md:w-[170px]">32cm</span>
                      <span className="w-[108px] md:w-[170px]">12.6in</span>
                    </span>
                    <span className="gap-[16px] flex py-[8px] border-t border-[#303739]">
                      <span className="w-[108px] md:w-[170px]">Depth</span>{" "}
                      <span className="w-[108px] md:w-[170px]">38cm</span>
                      <span className="w-[108px] md:w-[170px]">15in</span>
                    </span>
                    <span className="gap-[16px] flex py-[8px] border-t border-b border-[#303739]">
                      <span className="w-[108px] md:w-[170px]">Weight</span>{" "}
                      <span className="w-[108px] md:w-[170px]">5.45kg</span>
                      <span className="w-[108px] md:w-[170px]">12lbs</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="md:h-[120px] h-[64px]"></div>
        )}
      </>
    </section>
  );
};

export default Product;
