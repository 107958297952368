import { useEffect, useState } from "react";
import drop from "../../../Assets/dropdown.svg";

const ItemOption = ({ title, img, materials, onUpdate, selectedMaterial }) => {
  const [isExpanded, setisExpanded] = useState(false);

  useEffect(() => {
    console.log(selectedMaterial);
  }, [selectedMaterial]);

  return (
    <li>
      <h2 className="md:leading-[20px] leading-[20px] md:tracking-[0.48px] tracking-[0.56px] md:text-[16px] text-[14px] mb-[6px]">
        {title}
      </h2>
      <div className="flex justify-between items-center py-[8px] border-[1px] border-r-0 border-l-0 border-[#303739]">
        <div className="flex items-center gap-[12px]">
          <img
            src={selectedMaterial.img}
            alt=""
            className="h-[36px] w-[36px]"
          />
          <span className="md:tracking-[0.48px] tracking-[0.56px] md:text-[16px] md:leading-[20px] text-[14px] leading-[18px]">
            {selectedMaterial.name}
          </span>
        </div>
        <button onClick={() => setisExpanded((prevState) => !prevState)}>
          <img
            src={drop}
            alt=""
            className="rotate-90"
          />
        </button>
      </div>

      {/* Drop */}
      {isExpanded && (
        <ul>
          {/* opt 1 */}
          {
            materials.map((material) => (
              <div onClick={() => onUpdate(material)} className="flex justify-between items-center py-[8px] hover:bg-[#efefef]">
                <div className="flex items-center gap-[12px]">
                  <img
                    src={material.img}
                    alt=""
                    className="h-[36px] w-[36px]"
                  />
                  <span className="md:tracking-[0.48px] tracking-[0.56px] md:text-[16px] md:leading-[20px] text-[14px] leading-[18px]">
                    {material.name}
                  </span>
                </div>
              </div>
            ))
          }
        </ul>
      )}
    </li>
  );
};

export default ItemOption;
