import RedirectButton from "../../components/RedirectButton";

const Formalities = () => {
  return (
    <section className="px-[16px] w-full items-center flex flex-col justify-center">
      {/* Gradient */}
      <div className="absolute top-0 -z-10 w-full">
        <div className="h-[68px] md:h-[116px] bg-[#E8E8E3]"></div>
        <div className="h-[68px] md:h-[116px] bg-gradient-to-b from-[#E8E8E3] to-[#EDEDEA00]"></div>
      </div>

      <div className="w-full max-w-[804px] flex-col">
        <h2 className="text-[#2D3745] z-10 text-center mt-[84px] mb-[54px] text-[50px] leading-[40px] md:text-[96px] md:leading-[76px] md:mt-[156px] md:mb-[120px]">
          Formalities
        </h2>

        <div className="max-w-full flex justify-center flex-wrap">
          {/* SP–01 lead time */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[48px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              SP–01 lead time
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              All SP-01 speaker models are handmade to order and ship from
              Canada within 6-8 weeks. Please allow additional time for us to
              complete orders that incorporate custom finishes. {" "}
            </p>
          </div>

          {/* SP–01 custom orders */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[48px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              SP–01 custom orders{" "}
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739] mb-[24px] md:mb-[36px]">
              WAF offers custom finishes for our SP-01 model on request. We
              welcome dialogues with interior designers and those who wish to
              personalize their pair of SP-01 speakers even further. Specific
              grill textiles and custom base colors can be integrated for an
              additional cost. Please note that due to the technicalities of
              acoustic engineering, custom sizes cannot be ordered at this time.
              Keep an eye out for new products!{" "}
            </p>

            <RedirectButton text={"Contact us"} />
          </div>

          {/* Shipping (North America) */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[48px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              Shipping (North America){" "}
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              All WAF products are packaged with care and ship fully insured via
              UPS within North America. Audio Equipment is made to order and
              will ship out in 6-8 weeks. All accessories listed as In Stock
              will be dispatched within 5 working days of purchase. Once an
              order has been shipped, you will receive an email with your
              shipping information and a tracking number. Customers will
              automatically be charged the applicable taxes based on their
              location.{" "}
            </p>
          </div>

          {/* Shipping (International) */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[48px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              Shipping (International){" "}
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              All accessories will be shipped via UPS within 5 working days of
              purchase. Orders for audio equipment, including our SP-01 models,
              will require a custom shipping quote to destinations outside of
              North America. Please contact us at{" "}
              <a
                href="mailto:info@wafaudio.com"
                className="underline"
              >
                info@wafaudio.com
              </a>{" "}
              for shipping options. Customers are responsible for paying all
              customs charges and duties associated with international shipping.
              WAF will not pay, charge, or reimburse any fees corresponding to
              international custom duties.{" "}
            </p>
          </div>

          {/* Returns and exchanges */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[48px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              Returns and exchanges{" "}
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              Audio equipment:
              <br />
              <br />
              All audio equipment including our SP-01 models are made to order
              and cannot be returned or refunded. If you encounter an issue with
              your product, please promptly let us know and we will make
              arrangements on a case by case basis. <br />
              <br />
              Accessories:
              <br />
              <br />
              WAF does not offer refunds for accessories (T-Shirts, hats, and
              slipmats, etc.). If you are not satisfied with your product or
              require a different size or color, WAF will provide an exchange
              for products of the same or similar value within 14 days of
              delivery. Customers are responsible for all additional shipping
              costs associated with the return and exchange of products, which
              must be returned unused and in their original condition. Limited
              Edition and Sale products and accessories are not returnable.
            </p>
          </div>

          {/* Damaged merchandise */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[48px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              Damaged merchandise{" "}
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              In the event that your products are delivered damaged or
              defective, please email us with an attached image of the issue
              within 48 hours or receipt. We will make arrangements on a case by
              case basis. If your package is lost or stolen, we will do what we
              can to assist you. However, we are no longer responsible once your
              order is in transit.
              <br />
              <br />
              Please email{" "}
              <a
                href="mailto:info@wafaudio.com"
                className="underline"
              >
                info@wafaudio.com
              </a>{" "}
              within 48 hours of delivery to inform us of any issues{" "}
            </p>
          </div>

          {/* Contact */}
          <div className="max-w-[804px] w-full md:mb-[120px] mb-[60px]">
            {/* Subtitle */}
            <h3 className="text-[16px] md:text-[24px] tracking-[0.48px] md:leading-[30px] leading-[20px] text-[#303739] mb-[24px] md:mb-[36px]">
              Contact{" "}
            </h3>

            {/* Paragraph */}
            <p className="md:text-[16px] text-[14px] md:leading-[20px] leading-[18px] md:tracking-[0.48px] tracking-[0.56px] text-[#303739]">
              Should you have any questions about an order or any WAF products
              or policies, please reach out to us at{" "}
              <a
                href="mailto:info@wafaudio.com"
                className="underline"
              >
                info@wafaudio.com
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Formalities;
