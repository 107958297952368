import Hero from "./Hero";
import Second from "./WavesAndFreq";
import Third from "./Introducing";
import Forth from "./SoundDesign";

const Main = () => {
  return (
    <>
      <Hero />
      <Second />
      <Third />
      <Forth />
    </>
  );
};

export default Main;
